import React, { useState } from "react";
import Link from "next/link";
import Logo from "./Logo";
import HeaderContactUs from "./HeaderContactUs";

export default function Navbar() {
  const [open, setopen] = useState(false);

  return (
    <nav className="navbar navbar-expand-md navbar-dark">
      <div className="navbar-brand">
        <Logo />
      </div>
      {process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid"  ? (
        <HeaderContactUs />
      ) : (
        <div className="header-contact-us">
          {/* <Link href="/incorps/home">
            INCORPORATE LLC
          </Link> */}
        </div>
      )}
      <button
        className={!open ? "navbar-toggler collapsed" : "navbar-toggler"}
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded={open}
        aria-label="Toggle navigation"
        onClick={() => setopen(!open)}
      >
        <span className="navbar-toggler-icon" />
      </button>
      {open && (
        <div className="collapse navbar-collapse " id="navbarContent">
          <ul className="navbar-nav mobile">
            {/* <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/incorps/home"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Incorporate LLC
              </Link>
            </li> */}
            <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Home
              </Link>
            </li>
            <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/status"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Order status
              </Link>
            </li>
            <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/pricing"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/about"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                About
              </Link>
            </li>
            <li className="nav-item" onClick={() => setopen(false)}>
              <Link
                href="/contactus"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}
